"use client";

import Image from "next/image";
import Link from "next/link";
import React, { Fragment, useState } from "react";
import LanguageChanger from "../../../../../components/LanguageChanger";

import PhoneIcon from "../../../../assets/icons/PhoneIcon";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "flowbite-react";
import AdvisorCallForm from "../../../../../components/AdvisorCallForm";

const Header = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);

  return (
    <Fragment>
      <header className="sticky top-0 z-50 h-[82px] bg-white">
        <div className="mx-auto flex h-full max-w-6xl items-center justify-between px-4">
          <div className="flex items-center">
            <Link href={""} className="shrink-0">
              <Image
                src={"/images/swiss-cover.png"}
                width={173}
                height={36}
                alt="swiss-cover"
                className="w-[173px] h-[36px] object-contain"
              />
            </Link>
          </div>
          <div className="flex items-center gap-2">
            <LanguageChanger />
            <button
              className="shrink-0 items-center justify-center whitespace-nowrap rounded-[56px] text-base font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-center bg-thirdy text-white hover:bg-thirdy/90 h-[50px] px-[21px] w-fit flex"
              onClick={() => setShow(true)}
            >
              <PhoneIcon />
              <span className="md:inline-flex hidden">{t("Header.key1")}</span>
            </button>
          </div>
        </div>
      </header>

      {/* Call modal */}
      <Modal dismissible show={show} onClose={() => setShow(false)} size={"xl"}>
        <Modal.Header className="border-0 pb-0 text-[20px] font-semibold items-center">
          {t("Header.key2")}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {/* Form */}
            <AdvisorCallForm />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Header;
